#Jumbotron {
    border-radius: 30px;
    background-image: url('../img/header.png');
    background-position: center center;
    background-size: cover;
}

/* Jumbotron h1 font size */
.fs-60 {
    font-size: 5.5vw;
    width: 80%;
    margin: auto;
    font-weight: bold;
}

.fs-60-mobile {
    font-size: 10vw;
}

/* Curved border for form input */
.jumbo-input {
    padding: 10px 60px 10px 40px;
    border-radius: 20px 0px 0px 20px;
}

/* Curved border for form input */
.jumbo-input-mb {
    padding: 0px 10px 0px;
    border-radius: 20px 0px 0px 20px;
}

.btn-primary {
    background-color: #5772ff;
}

/* Form submit hover color */
.btn-primary:hover {
    background-color: #4d61e4;
}

/* rounded edges for form */
.submit-btn {
    padding: 12px 40px 11px;
    border-radius: 0px 50px 50px 0px;
}


/* Section padding */
section {
    padding: 2.5em 0em;
}

/* Card body color */
.card {
    border-radius: 1em;
}

.card-body {
    background-color: #f9fcff;
    border-radius: 1em;
}

#SimpleSteps h3 {
    margin: 12px 0px;
}

#SimpleSteps p {
    width: 75%;
    margin: auto;
}

.cta-input {
    height: 56px;
    margin-bottom: 0px;
    padding: 14px 26px;
    border-style: solid;
    border-width: 1px;
    border-color: hsla(0, 0%, 100%, 0);
    border-radius: 50px;
    background-color: hsla(0, 0%, 100%, 0.1);
    -webkit-transition: border-color 200ms ease;
    transition: border-color 200ms ease;
    color: #fff;
    font-size: 18px;
    line-height: 120%;
}